import React, { useEffect, useState } from "react";
import Loading from "../../../../atoms/Loading";
import Check from "../../../../molecules/Check";
import styles, { submit_button, form_body, card_holder } from "./styles";
import useGetSiteMetaData from "../hooks/useGetSiteMetaData";
import data from "../../../../../data/payment.json";
import "./style.scss";

const element_id = "solid-payment-form-container";
const sdk_url = "https://cdn.solidgate.com/js/solid-form.js";

const fields = data.fields;

const SolidGatePSP = ({ paymentGatewayDetails, setState }) => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const {
    config: {
      payment: { showAgreeTermsAndCondition },
    },
  } = useGetSiteMetaData();

  const loadScript = (onload) => {
    const sdk = document.createElement("script");
    sdk.src = sdk_url;
    sdk.async = true;
    sdk.onload = onload;
    document.body.appendChild(sdk);
  };

  const preparePSP = () => {
    const config = {
      iframeParams: {
        containerId: element_id,
      },
      merchantData: {
        merchant: paymentGatewayDetails.merchant,
        signature: paymentGatewayDetails.signature,
        paymentIntent: paymentGatewayDetails.paymentIntent,
      },
      formParams: {
        submitButtonText: "Complete your order",
        isCardHolderVisible: true,
        formTypeClass: "default",
      },
      styles: {
        submit_button,
        form_body,
        card_holder,
        card_number: styles.input,
        card_cvv: styles.input,
        expiry_date: styles.input,
      },
    };

    /* eslint-disable-next-line */
    const form = PaymentFormSdk.init(config);

    form.on("mounted", onMounted);

    form.on("error", handleError);

    form.on("fail", handleError);

    form.on("sessionExpired", handleError);
  };

  const onMounted = () => {
    setLoading(false);
  };

  const handleError = (ev) => {
    console.error("error", ev);
    setState({ errorPayment: true });
    setLoading(false);
  };

  useEffect(() => {
    if (!scriptIsLoaded && paymentGatewayDetails) {
      setScriptIsLoaded(true);
      loadScript(preparePSP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptIsLoaded, paymentGatewayDetails]);

  const [termsAndConditionInput, setTermAndConditionInput] = useState({
    value: fields.termsAndConditions.value,
  });

  const handleOnchageTermsAndCondition = () => {
    setTermAndConditionInput({
      ...termsAndConditionInput,
      value: termsAndConditionInput.value === "0" ? "1" : "0",
    });
  };

  return (
    <>
      {loading && <Loading small />}
      <div
        id={element_id}
        className={styles.solidGate}></div>
      {showAgreeTermsAndCondition && (
        <div style={{ padding: "0 2.25rem", marginTop: "1.5rem" }}>
          <Check
            {...fields.termsAndConditions}
            value={termsAndConditionInput.value}
            checked={termsAndConditionInput.value}
            onChange={handleOnchageTermsAndCondition}
          />
        </div>
      )}
    </>
  );
};

export default SolidGatePSP;
