import React, { useCallback } from "react";
import FormComponents from "../../../FormComponents";
import Button from "../../../../atoms/Button";
import { cvvMessage } from "../../utilities";
import { refHandler } from "../../../../../services/componentSwitcher/ComponentSwitcher";
import useForm from "../hooks/useForm";
import { scrollToRef } from "../../utilities";

const H2hPSP = ({ gateway, isSubmitting, setState, payOrder }) => {
  const {
    form,
    formFields,
    showAgreeTermsAndCondition,
    setForm,
    updateField,
    fieldValidator,
    formValidator,
  } = useForm();

  const onSubmit = useCallback(() => {
    if (!isSubmitting) {
      const validate = formValidator(true);

      if (!validate) {
        return null;
      }

      let paymentData = {
        gatewayIdentifier: gateway,
        paymentDetails: {
          description: "",
          cardHolderName: form.cardHolder.value,
          cardNumber: form.cardNumber.value,
          cardExpirationMonth: form.cardExpirationMonth.value,
          cardExpirationYear: form.cardExpirationYear.value,
          cardCvv: form.cardCvv.value,
        },
      };

      payOrder({ paymentData }).catch(() => {
        payFormError();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gateway, isSubmitting]);

  const buildField = useCallback(
    (item, index) => {
      const field = form[item];
      const FieldComponent = FormComponents[field.component];

      if (field.name === "cardCvv") {
        const message = cvvMessage(form.cardNumber.value);
        field.placeholder = message.placeholder;
        field.helperMessage = message.helperMessage;
      }

      if (field.name === "termsAndConditions" && !showAgreeTermsAndCondition) {
        return null;
      }

      return (
        <React.Fragment key={`H2hPSP-${index}`}>
          {refHandler(field, form) && (
            <FieldComponent
              onBlur={fieldValidator}
              onChange={updateField}
              {...field}
              key={`H2hPSP-field-${index}`}
            />
          )}
        </React.Fragment>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [form, showAgreeTermsAndCondition, updateField, fieldValidator]
  );

  const payFormError = () => {
    scrollToRef();
    let formError = { ...form };

    Object.entries(formError).forEach((field) => {
      let fieldsToClear = [
        "cardHolder",
        "cardNumber",
        "cardExpiration",
        "cardExpirationYear",
        "cardCvv",
      ];
      fieldsToClear.includes(field[0]) && (field[1].value = "");
    });

    formError.config.error = true;
    setForm(formError);
  };

  return (
    <>
      <div className="m-blockForm">{formFields.map(buildField)}</div>
      <Button
        primary
        full
        dataQa={`complete-order`}
        label="Complete your order"
        onClick={onSubmit}
      />
    </>
  );
};

export default H2hPSP;
