import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Loading from "../../atoms/Loading";
import DotsLoader from "../../atoms/DotsLoader";
import PreventReload from "../../atoms/PreventReload";
import { delay } from "../../utils";

const messages = [
  "Your payment is being processed",
  "Verifying card information",
  "Securing connection with payment provider",
  "Authorizing payment",
];

const PaymentSubmittingLoader = ({ isSubmitting }) => {
  const [message, setMessage] = useState(messages[0]);

  const handleMessageToShow = async () => {
    for (let index = 1; index < messages.length; index++) {
      await delay(1000 * 5);

      const msj = messages[index];
      setMessage(msj);
    }
  };

  useEffect(() => {
    handleMessageToShow();
  }, []);

  return (
    <PreventReload isActive={isSubmitting}>
      <Loading
        full
        message={
          isSubmitting ? (
            <>
              <div className="Pay__submittingLoader">
                {message}
                <DotsLoader />
              </div>
              <b>Please do not reload the page</b>
            </>
          ) : null
        }
      />
    </PreventReload>
  );
};

PaymentSubmittingLoader.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
};

export default PaymentSubmittingLoader;
