import React, { useCallback, useEffect, useState } from "react";
import money from "../../../images/payment/money.png";
import OrderSummary from "../OrderSummary";
import { GetOrderHandler } from "../../../services/api/GetOrder/GetOrderHandler";
import { buildAddons, calculateTotal } from "../../utils/addons";
import { ContactSummary } from "../ContactSummary";
import "./styles.scss";

export const SideSummary = ({
  application,
  setTotal,
  enableOrderRequest = true,
  isOpen,
  ...props
}) => {
  const [addons, setAddons] = useState([]);
  const [price, setPrice] = useState(0);
  const [pdf, setPdf] = useState({});

  const handleBuildAddons = (addons) => {
    const buildedAddons = buildAddons(addons);
    return buildedAddons;
  };

  const getOrder = useCallback(async () => {
    if (application.applicationId) {
      const orderHandler = new GetOrderHandler({});

      const { form } = await orderHandler.customAction({
        applicationId: application.applicationId,
      });

      if (form.addons && Array.isArray(form.addons)) {
        form.addons = handleBuildAddons(form.addons);
      } else {
        form.addons = [];
      }

      if (typeof application.applicationPrice === "object" && application.applicationPrice.amount) {
        setPrice(application.applicationPrice.amount);

        if (typeof setTotal === "function") {
          setTotal(calculateTotal(application.applicationPrice.amount, form.addons), form.addons);
        }
      }

      setAddons(form.addons);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.applicationId, application.applicationPrice.amount]);

  useEffect(() => {
    getOrder();
  }, [getOrder, enableOrderRequest]);

  useEffect(() => {
    setPdf({
      name: application.applicationName,
      description: application.applicationNumber,
    });
  }, [application.applicationName, application.applicationNumber]);

  return (
    <div className={`sideSummary ${!props.showDetails && "sideSummary--small"}`}>
      <OrderSummary
        applicationReference={application.applicationReference}
        addons={addons}
        price={price}
        pdf={pdf}
        thankYou={props.thankYou}
        payment={props.payment}
        isOpen={isOpen}
      />
      <ContactSummary payment={props.payment} />
      {props.showDetails && (
        <div className="sideSummary__wrapper sideSummary__light sideSummary--details">
          <div className="sideSummary__body">
            <div className="sideSummary__title">Complete your order now to:</div>
            <ul className="sideSummary__list">
              <li className="sideSummary__list-item">
                Export your ready-to-submit passport application file to PDF.
              </li>
              <li className="sideSummary__list-item">
                Avoid costly mistakes with 24/7 customer support and expert guidance on submitting
                an error-free application.
              </li>
              <li className="sideSummary__list-item">
                Get detailed instructions on how to complete your application.
              </li>
            </ul>
          </div>
          <div className={`sideSummary__footer`}>
            <img
              className={`sideSummary__footer-logo`}
              src={money}
              alt="Money back guarantee"
            />
            <p className={`sideSummary__footer-text`}>
              <span>Money back guarantee</span>
              You have up to 120 days after payment to request a refund.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
