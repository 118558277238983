import React, { useEffect, useMemo } from "react";
import { Layout } from "../../components/Layout/layout";
import { amplitudeTrackEvent } from "../../components/utils/Amplitude";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import Loading from "../../components/atoms/Loading";
import PaymentCertificates from "../../components/Form/PaymentCertificates";
import Nav from "../../components/atoms/Nav";
import Pay from "../../components/Form/Pay";
import Alert from "../../components/atoms/Alert";
import Timer from "../../components/atoms/Timer";
import { SideSummary } from "../../components/organisms/SideSummary";
import { Title } from "../../components/atoms/Title/title";
import useGetApplication from "../../hooks/useGetApplication";
import useMediaQuery from "../../hooks/useMediaQuery";
import { windowIsExist } from "../../components/utils/index";
import "./styles.scss";

const paymentSteps = ["Form Complete", "Review", "Payment", "Form Received"];

const PaymentPage = () => {
  const isMobile = useMediaQuery("mobile");
  const { application, getApplicationData, loading, iframeLoaderContainer } = useGetApplication({
    redirect: { allow: true, to: "/" },
  });

  const applicationTitle = useMemo(() => {
    if (application.applicationNumber && application.applicationName) {
      return `${application.applicationNumber} - ${application.applicationName} Form`;
    }

    return null;
  }, [application.applicationNumber, application.applicationName]);

  useEffect(() => {
    getApplicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (application.applicationName) {
      amplitudeTrackEvent("view_page_payment", {
        form: application.applicationName.toLowerCase(),
      });
    }
  }, [application.applicationName]);

  const isMobileShowTest = windowIsExist && window.innerWidth < 790;

  return (
    <Layout
      hideMenuButton={false}
      headerTitle={applicationTitle}
      hideFooterNavigation={true}
      hideFooterNavigationLogo={true}>
      <Wrapper
        collapse
        rowWrap
        buttonsContainer>
        <Nav
          desktopOnly
          totalSteps={paymentSteps.length}
          stepNames={paymentSteps}
          currentStep={paymentSteps.length - 2}
        />
        <div id={`js-iframe-verifyPayment`}></div>
      </Wrapper>
      {!iframeLoaderContainer && (
        <Wrapper className="Payment__grid">
          {loading ? (
            <Loading full />
          ) : (
            <>
              <div className={`Payment__wrapper`}>
                <div className={`Payment__header`}>
                  <Title
                    regular
                    content={`Download Your Document After One More Step`}
                  />
                </div>
                <div className={`Payment__body`}>
                  {!application.emptyGatways && (
                    <Alert
                      type="warning"
                      className="Payment__timer"
                      compact
                      center>
                      <Timer /> Left to Download Your Document
                    </Alert>
                  )}
                  <iframeLoaderContainer />
                  <Pay
                    application={application}
                    testAB={isMobileShowTest}
                  />
                </div>
              </div>
              <PaymentCertificates />
              <SideSummary
                application={application}
                applicationID={application.applicationName}
                applicationName={application.applicationNumber}
                applicationPrice={application.applicationPrice.amount}
                applicationReference={application.applicationReference}
                showDetails
                payment
                isOpen={isMobile ? false : true}
              />
            </>
          )}
        </Wrapper>
      )}
    </Layout>
  );
};

export default PaymentPage;
