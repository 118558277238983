import React, { useEffect, useState } from "react";
import Button from "../../../atoms/Button";
import TextInput from "../../TextInput";
import Check from "../../../molecules/Check";
import data from "../../../../data/payment.json";
import { cvvMessage } from "../utilities";
import useGetSiteMetaData from "./hooks/useGetSiteMetaData";
import Loading from "../../../atoms/Loading";
import Tooltip from "../../Tooltip/tooltip";

const cvvHelpMessage = cvvMessage("").helperMessage;

const element_id = "collectjs";
const fields = data.fields;

const NmiPSP = ({ gateway, fieldChecked, payOrder, paymentGatewayDetails, setState }) => {
  const {
    config: {
      payment: { showAgreeTermsAndCondition },
    },
  } = useGetSiteMetaData();

  const [cardHolder, setCardHolder] = useState("");
  const [termsAndConditionInput, setTermAndConditionInput] = useState({
    value: fields.termsAndConditions.value,
    error: false,
  });

  const [validForm, setValidForm] = useState({
    cardHolder: false,
    ccnumber: false,
    ccexp: false,
    cvv: false,
    buttonDisable: true,
  });

  const finishSubmit = (response) => {
    const paymentData = {
      gatewayIdentifier: gateway,
      paymentDetails: {
        description: "",
        cardHolderName: document.getElementById("cardHolder").value,
        cardNumber: response.card.number,
        paymentToken: response.token,
      },
    };

    payOrder({ paymentData });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (showAgreeTermsAndCondition && termsAndConditionInput.value === "0") {
      return setTermAndConditionInput({ ...termsAndConditionInput, error: true });
    }

    window.CollectJS.startPaymentRequest();
  };

  const handleOnchageTermsAndCondition = () => {
    setTermAndConditionInput({
      ...termsAndConditionInput,
      value: termsAndConditionInput.value === "0" ? "1" : "0",
      error: false,
    });
  };

  const fakeInputStyles = {
    "background-color": "#e7ebee",
    "border-radius": "6px",
    "font-size": "1rem",
    height: "40px",
    border: "1px solid transparent",
    "text-overflow": "ellipsis",
    margin: "0.0005em",
    width: "100%",
  };

  const onLoad = () => {
    window.CollectJS.configure({
      variant: "inline",
      invalidCss: {
        "box-sizing": "border-box",
        border: "1px solid #d63837",
        "background-color": "#ffecee",
      },
      validCss: {
        border: "1px solid #13AA73",
      },
      customCss: { ...fakeInputStyles, padding: "0.75rem 0 0.75rem 0.75rem" },
      focusCss: {
        border: "1px solid #4d72b2",
      },
      placeholderCss: {
        color: "#b5babd",
        "font-weight": "100",
      },
      validationCallback: function (fieldName, valid, message) {
        if (valid) {
          document.querySelector("#" + fieldName).parentNode.classList.remove("is-invalid");
          document.querySelector("#" + fieldName + "Error").innerHTML = "";
          setValidForm((validForm) => ({ ...validForm, [fieldName]: true }));
        } else {
          setState({ isSubmitting: false, loading: false });
          document.querySelector("#" + fieldName).parentNode.classList.add("is-invalid");
          document.querySelector("#" + fieldName + "Error").innerHTML = message;
          setValidForm((validForm) => ({ ...validForm, [fieldName]: false }));
        }
      },
      callback: finishSubmit,
      fields: {
        ccnumber: {
          placeholder: "e.g.: 2111 1111 1111 1111",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "MM/YY",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "123",
          selector: "#cvv",
        },
      },
    });
  };

  const onChangeCardHolder = (ev) => {
    const value = ev.target.value;

    setCardHolder(value);
    validateCardHolder(value);
  };

  const validateCardHolder = (value) => {
    const containOnlyCharacters = /^[a-zA-Z\s]*$/.test(value);

    const isValidField = value.length > 3 && containOnlyCharacters;

    if (isValidField) {
      fields.cardHolder["validField"] = true;
      fields.cardHolder["validate"] = true;
      setValidForm({
        ...validForm,
        cardHolder: true,
      });
    } else {
      fields.cardHolder["validField"] = false;
      fields.cardHolder["validate"] = false;
      setValidForm({
        ...validForm,
        cardHolder: false,
      });
    }
  };

  useEffect(() => {
    if (
      paymentGatewayDetails &&
      paymentGatewayDetails.public_key &&
      paymentGatewayDetails.public_key !== ""
    ) {
      if (typeof document !== "undefined") {
        const script = document.createElement("script");
        script.src = "https://secure.safewebservices.com/token/Collect.js";
        script.setAttribute("id", element_id);
        script.setAttribute("data-tokenization-key", paymentGatewayDetails.public_key);
        document.body.appendChild(script);

        document.getElementById(element_id).addEventListener("load", onLoad);
      }
    }
    return () => {
      if (typeof document !== "undefined") {
        const element = document.getElementById(element_id);
        if (element) {
          element.removeEventListener("load", onLoad);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentGatewayDetails]);

  function onLoadNmiIframe() {
    document.getElementById("nmiLoading").remove();
  }

  useEffect(() => {
    function checkIframeLoaded() {
      const iframe = document.getElementById("CollectJSInlinecvv");

      if (iframe) {
        window.setTimeout(onLoadNmiIframe, 2000);
        return;
      }

      window.setTimeout(checkIframeLoaded, 200);
    }

    checkIframeLoaded();
  }, []);

  useEffect(() => {
    if (validForm.cardHolder && validForm.ccnumber && validForm.ccexp && validForm.cvv) {
      setValidForm((validForm) => ({ ...validForm, buttonDisable: false }));
    } else {
      setValidForm((validForm) => ({ ...validForm, buttonDisable: true }));
    }
  }, [validForm.cardHolder, validForm.ccnumber, validForm.ccexp, validForm.cvv]);

  return (
    <div
      className="m-blockForm"
      style={{
        display: `${fieldChecked ? "block" : "none"}`,
      }}>
      <form onSubmit={onSubmit}>
        <div className="Pay-item__payment">
          <div>
            <div
              id="nmiLoading"
              className="Pay__item__nmi-loading">
              <Loading dark />
            </div>
            <TextInput
              {...fields.cardHolder}
              id="cardHolder"
              value={cardHolder}
              onChange={onChangeCardHolder}
            />
            <div className={`e-text ${fields.cardNumber.class}`}>
              <div className="inputLabel">
                <p>{fields.cardNumber.label}</p>
              </div>
              <div
                id="ccnumber"
                style={fakeInputStyles}
              />
              <span
                id="ccnumberError"
                className="e-text-error"></span>
            </div>
            <div className={`e-text ${fields.cardExpiration.class}`}>
              <div className="inputLabel">
                <p>{fields.cardExpiration.label}</p>
              </div>
              <div
                id="ccexp"
                style={fakeInputStyles}
              />
              <span
                id="ccexpError"
                className="e-text-error"></span>
            </div>
            <div className={`e-text ${fields.cardCvv.class}`}>
              <div className="inputLabel">
                <p>
                  {fields.cardCvv.label}
                  {fields.cardCvv.tooltip && (
                    <Tooltip
                      position="left"
                      effect="solid"
                      event="click"
                      tip={fields.cardCvv.name}
                      dataFor={`m-tooltip__${fields.cardCvv.name}`}
                      content={fields.cardCvv.tooltip}
                    />
                  )}
                </p>
              </div>
              <div
                id="cvv"
                style={fakeInputStyles}
              />
              <div className="e-text-helper">{cvvHelpMessage}</div>
              <span
                id="cvvError"
                className="e-text-error"></span>
            </div>
          </div>

          {showAgreeTermsAndCondition && (
            <Check
              {...fields.termsAndConditions}
              value={termsAndConditionInput.value}
              checked={termsAndConditionInput.value}
              validate={!termsAndConditionInput.error}
              onChange={handleOnchageTermsAndCondition}
            />
          )}

          <div className="Pay-item--input-cta">
            <Button
              primary
              full
              dataQa={`complete-order`}
              label="Complete your order"
              buttonId="payButton"
              buttonSubmit={true}
              onClick={() => setState({ isSubmitting: true, loading: true })}
              disabled={validForm.buttonDisable}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default NmiPSP;
