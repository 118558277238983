import React from "react";
import security from "../../../images/icons/security.png";

const SecurityFooter = ({ className }) => {
  return (
    <div className={className}>
      <div className={`security-text`}>
        <img
          className={`paymentForm__security-icon`}
          src={security}
          alt="Security icon"
        />
        <p>
          Your personal information is securely encrypted by Secure Sockets Layer (SSL) software.
        </p>
      </div>
    </div>
  );
};

export default SecurityFooter;
