import { useMemo, useState, useCallback } from "react";
import Form from "../../../../../data/payment.json";
import useGetSiteMetaData from "./useGetSiteMetaData";
import { TypeSelector } from "../../../../../services/componentSwitcher/TypeSelector";
import { scrollToRef, isAmex } from "../../utilities";
import { getParamInUrl } from "../../../../utils";
import { getCookie } from "../../../../utils/cookies";
import cloneDeep from "lodash/cloneDeep";
import valid from "card-validator";

const useForm = () => {
  const {
    config: {
      payment: { showAgreeTermsAndCondition },
    },
  } = useGetSiteMetaData();

  const defaultForm = useMemo(() => {
    const form = cloneDeep(Form.fields);

    if (!showAgreeTermsAndCondition) {
      delete form.termsAndConditions;
    }

    const orderCookie = getCookie("address_token");
    const param_id = getParamInUrl("id");

    form.applicationId.value = param_id || orderCookie.order;

    return form;
  }, [showAgreeTermsAndCondition]);

  const formStructure = useMemo(() => {
    const data = cloneDeep(Form.structure);

    if (!showAgreeTermsAndCondition) {
      data.forms[0].fields = data.forms[0].fields.filter((key) => key !== "termsAndConditions");
    }
    return data;
  }, [showAgreeTermsAndCondition]);

  const formFields = useMemo(() => formStructure.forms[0].fields, [formStructure]);

  const [form, setForm] = useState(defaultForm);

  const formValidator = useCallback(
    (scrollErrors) => {
      let formData = { ...form };

      if (!showAgreeTermsAndCondition) {
        delete formData.termsAndConditions;
      }

      delete formData.paymentSelect;

      formData.config.validate = true;

      let currentError = "";

      Object.keys(formData).forEach(function (objectKey) {
        const eventMock = {
          target: {
            name: objectKey,
            value: formData[objectKey].value,
          },
        };

        if (!fieldValidator(eventMock)) {
          formData.config.validate = false;
          if (currentError === "") {
            currentError = objectKey;
          }
        }
      });

      if (currentError && scrollErrors) {
        scrollToRef(currentError);
      }
      setForm(formData);

      return formData.config.validate;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form]
  );

  const fieldValidator = useCallback(
    (event) => {
      const objectKey = event.target.name;
      const value = event.target.value;
      let formData = { ...form };

      if (value === "") {
        formData[objectKey].validate = false;
      } else {
        switch (objectKey) {
          case "cardHolder":
            const containOnlyCharacters = /^[a-zA-Z\s]*$/.test(value);
            const isValidField = value.length > 3 && containOnlyCharacters;

            formData[objectKey].validField = isValidField ? true : false;
            formData[objectKey].validate = isValidField ? true : false;
            break;
          case "cardNumber":
            const isValidNumber = valid.number(value);
            formData[objectKey].validate = isValidNumber.isValid ? true : false;
            break;
          case "cardCvv":
            const cardNumber = formData["cardNumber"].value;

            let isValidCVV = "";
            if (isAmex(cardNumber)) {
              isValidCVV = valid.cvv(value, 4);
            } else {
              isValidCVV = valid.cvv(value);
            }

            formData[objectKey].validate = isValidCVV.isValid ? true : false;
            break;
          case "cardExpiration":
            const isValidCardExpiration = valid.expirationDate(value);
            formData[objectKey].validate = isValidCardExpiration.isValid ? true : false;
            break;
          default:
            formData[objectKey].validate = true;
        }
      }

      if (formData[objectKey].value === "0" && formData[objectKey].type === "checkbox") {
        formData[objectKey].validate = false;
      }

      setForm(formData);
      return formData[objectKey].validate;
    },
    [form]
  );

  const updateField = useCallback(
    (event) => {
      let formData = { ...form };

      if (event.target.type === "checkbox") {
        let value = event.target.value,
          name = event.target.name,
          field = formData[name];
        formData[event.target.name].value = TypeSelector(
          field.component,
          value,
          event.target.checked
        );
      } else {
        formData[event.target.name].value = event.target.value;
      }

      if (event.target.name === "cardExpiration") {
        const expirationDate = formData.cardExpiration.value.split("/");
        formData.cardExpirationMonth.value = expirationDate[0];
        formData.cardExpirationYear.value = expirationDate[1];
      }

      setForm(formData);

      if (formData.config.validate === false) {
        formValidator(false);
      }
    },
    [form, formValidator]
  );

  return {
    form,
    setForm,
    defaultForm,
    formStructure,
    formFields,
    showAgreeTermsAndCondition,
    updateField,
    fieldValidator,
    formValidator,
  };
};

export default useForm;
