import "./paymentCertificates.scss";
import React from "react";
import comodo from "../../../images/payment/comodo_secure.png";
import ssl from "../../../images/payment/ssl.png";

export default class PaymentCertificates extends React.Component {
  render() {
    return (
      <div className={`paymentCertificates__wrapper`}>
        <div className={`paymentCertificates__header`}>Our security, your safety</div>
        <div className={`paymentCertificates__item`}>
          <img
            className={`paymentCertificates__item-logo`}
            src={comodo}
            alt="payment certificates"
          />
          <p className={`paymentCertificates__item-text`}>
            Your information is defended via secure data protection to keep it private and
            confidential.
          </p>
        </div>
        <div className={`paymentCertificates__item`}>
          <img
            className={`paymentCertificates__item-logo`}
            src={ssl}
            alt="payment certificates"
          />
          <p className={`paymentCertificates__item-text`}>
            This site is secured by SSL. Your credit card information is fully protected by an
            encryption system.
          </p>
        </div>
      </div>
    );
  }
}
